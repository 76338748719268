<template>
  <div>
    <div class="py-2">
      <div class="px-2 d-flex justify-content-between align-items-center">
        <label class="header-text">ประวัติคะแนนสะสม</label>
        <b-button
          class="filter-slide-btn d-flex align-items-center"
          @click="showFilter"
          ><font-awesome-icon icon="filter" title="filter-btn" /><span
            class="mx-2"
            >กรองข้อมูล</span
          >
          <font-awesome-icon
            :color="$store.state.colorPrimary"
            icon="chevron-circle-left"
            title="filter-btn"
          />
        </b-button>
      </div>
      <div>
        <b-table
          responsive
          class="mt-2 text-center table-list mb-0 table-p-th"
          hover
          :tbody-tr-class="trTableReport"
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="ไม่มีรายการข้อมูลการซื้อ"
        >
          <template v-slot:head(member_id)="">
            <p class="mb-0">หมายเลขสมาชิก</p>
            <p class="mb-0">(Member ID)</p>
          </template>
          <template v-slot:head(name)="">
            <p class="mb-0">ชื่อสมาชิก</p>
            <p class="mb-0">(Name)</p>
          </template>
          <template v-slot:head(telephone)="">
            <p class="mb-0">หมายเลขโทรศัพท์</p>
            <p class="mb-0">(Phone no.)</p>
          </template>
          <template v-slot:head(member_type)="">
            <p class="mb-0">ระดับสมาชิก</p>
            <p class="mb-0">(Member tier)</p>
          </template>
          <template v-slot:head(center_point)="">
            <p class="mb-0">คะแนนรวม</p>
            <p class="mb-0">(Point balance)</p>
          </template>
          <template v-slot:head(last_updated)="">
            <p class="mb-0">วันที่อัพเดตล่าสุด</p>
            <p class="mb-0">(Last update)</p>
          </template>
          <template v-slot:cell(member_id)="data">
            <div v-if="data.item.member_id">
              <label>{{ data.item.member_id }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(name)="data">
            <div v-if="data.item.name">
              <label>{{ data.item.name }}</label
              ><label class="ml-2">{{ data.item.surname }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(telephone)="data">
            <div v-if="data.item.telephone">
              <label>{{ data.item.telephone }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(member_type)="data">
            <div v-if="data.item.member_type">
              <label>{{ data.item.member_type }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(center_point)="data">
            <div v-if="data.item.center_point >= 0">
              <label>{{
                (data.item.center_point + data.item.branch_point)
                  | numeral("0,0")
              }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(last_updated)="data">
            <div v-if="data.item.last_updated">
              <label>{{ new Date(data.item.last_updated) | moment($formatDateTime) }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <div class="mt-2">
          <b-pagination
           size="sm"
            limit="4"
            v-model="filter.page"
            :total-rows="rows"
            :per-page="filter.perPage"
            class="m-md-0 page-item"
            @change="pagination"
            align="center"
          ></b-pagination>
        </div>
      </div>
      <SlideFilter
        ref="SlideFilter"
        :filter="filter"
        @onSubmitFilter="getPointList"
      />
      <ModalLoading ref="modalLoading" :hasClose="false" />
    </div>
  </div>
</template>

<script>
import SlideFilter from "./components/SlideFilter.vue";
import ModalLoading from "@/components/modal/alert/ModalLoading";
export default {
  name: "PointIndex",
  components: {
    SlideFilter,
    ModalLoading,
  },
  data() {
    return {
      isBusy: false,
      filter: {
        memberId: "",
        name: "",
        telephone: "",
        memberLevel: [1, 23],
        page: 1,
        perPage: 8,
      },
      rows: 0,
      items: [],
      fields: [
        {
          key: "member_id",
          label: "หมายเลขสมาชิก",
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "name",
          label: `ชื่อสมาชิก`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "250px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "telephone",
          label: `หมายเลขโทรศัพท์`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "member_type",
          label: `ระดับสมาชิก`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "center_point",
          label: `คะแนนรวม`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "last_updated",
          label: `วันอัพเดตล่าสุด`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
      ],
      trTableReport: {
        "border-bottom": "1px solid #D9D9D9",
      },
    };
  },
  async created() {
    this.getPointList();
  },
  methods: {
    getPointList() {
      this.isBusy = true;
      this.axios
        .post(`${this.$baseUrl}/report/get_point_balance`, this.setBody())
        .then((data) => {
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
          }
          this.isBusy = false;
        });
    },
    showFilter() {
      this.$refs.SlideFilter.show();
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getPointList();
    },
    setBody() {
      return {
        skip: (this.filter.page - 1) * this.filter.perPage,
        take: this.filter.perPage,
        name: this.filter.name,
        telephone: this.filter.telephone,
        member_id: this.filter.memberId,
        member_level: this.filter.memberLevel.join(),
        branch_id: this.$cookies.get("branch_id"),
      };
    },
    LightenDarkenColor(col, amt) {
      var usePound = false;

      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col, 16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-slide-btn {
  background-color: #e8e9ec;
  border: none;
}
</style>
