import { render, staticRenderFns } from "./SlideFilter.vue?vue&type=template&id=db1bbee4&scoped=true"
import script from "./SlideFilter.vue?vue&type=script&lang=js"
export * from "./SlideFilter.vue?vue&type=script&lang=js"
import style0 from "./SlideFilter.vue?vue&type=style&index=0&id=db1bbee4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db1bbee4",
  null
  
)

export default component.exports