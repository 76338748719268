<template>
  <div>
    <b-sidebar
      id="sidebar-filter"
      v-model="showSlide"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      aria-labelledby="sidebar-filter-title"
      @hidden="hide()"
      no-header
    >
      <template>
        <div>
          <div class="p-2 d-flex justify-content-between align-items-center">
            <label class="header-text">กรองข้อมูล</label>
            <label class="pointer" @click="hide"
              ><b-icon icon="x-circle-fill"></b-icon
            ></label>
          </div>
          <div>
            <div class="p-2">
              <InputText
                textFloat="หมายเลขสมาชิก (Member ID)"
                v-model="filter.memberId"
                class="pb-2"
                placeholder="Member ID"
                type="text"
              />
              <InputText
                textFloat="ชื่อสมาชิก (Member Name)"
                v-model="filter.name"
                class="pb-2"
                placeholder="Name"
                type="text"
              />
              <InputText
                textFloat="หมายเลขโทรศัพท์ (Phone No.)"
                v-model="filter.telephone"
                class="pb-2"
                placeholder="Phone No."
                type="text"
              />
              <div>
                <div class="font-weight-bold header-text">
                  ระดับสมาชิก (Member tier)
                </div>
                <div class="pl-2 pr-2">
                  <b-col cols="12">
                    <b-form-checkbox
                      v-model="selectMemberLevelAll"
                      @change="selectAll"
                    >
                      All
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    v-for="(m, index) in MemberOption"
                    :key="index"
                    cols="6"
                  >
                    <b-form-checkbox
                      v-model="filter.memberLevel"
                      :value="m.value"
                      class="text-nowrap"
                    >
                      {{ m.text }}
                    </b-form-checkbox>
                  </b-col>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="text-center p-2">
          <p @click="clearFilter"><u>ล้างตัวกรอง</u></p>
          <b-button class="radius-btn bg-main mt-2" block @click="submitForm"
            ><span>ค้นหา</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  name: "SlideFilter",
  components: { InputText },
  props: {
    filter: {
      type: Object,
      isRequired: true,
    },
  },
  data() {
    return {
      showSlide: false,
      selectMemberLevelAll: true,
      MemberOption: [
        {
          text: "Member",
          value: 1,
        },
        {
          text: "Non-Member",
          value: 23,
        },
      ],
    };
  },
  watch: {
    "filter.memberLevel": {
      handler(val) {
        if (this.filter.memberLevel.length == this.MemberOption.length) {
          this.selectMemberLevelAll = true;
        } else {
          this.selectMemberLevelAll = false;
        }
      },
    },
  },
  methods: {
    show() {
      this.showSlide = true;
    },
    hide() {
      this.showSlide = false;
    },
    selectAll(value) {
      if (value) {
        this.filter.memberLevel = this.MemberOption.map((m, i) => {
          return m.value;
        });
      } else {
        this.filter.memberLevel = [];
      }
    },
    async clearFilter() {
      this.filter.memberId = "";
      this.filter.name = "";
      this.filter.telephone = "";
      this.filter.member_level = [];
      this.filter.page = 1;
      this.filter.perPage = 8;
      this.$emit("onSubmitFilter");
    },
    async submitForm() {
      await this.$emit("onSubmitFilter");
      this.hide();
    },
  },
};
</script>

<style scoped>
.input-container {
  display: flex;
  height: 100%;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
</style>
